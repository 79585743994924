var i18n = {
    en: {
        'main-title': 'AndoTech Cup - Tennis Tournament',
        'main-desc': 'International edition',
        'main-date': '1 - 5 June 2017',
        'page-title': 'AndoTech Cup',
        'home-btn': 'Home',
        'ddl2-btn': 'Other pages',
        'ddl3-btn': 'Stuff',
        'register-btn': 'Registration',
        'more-info': 'Learn more',
        'description': '"Andotech Cup" is a tennis tournament dedicated to amateur players, organized by S.C. Andotech S.R.L in collaboration with "Tenis Arena" in Brasov. ',
        'about-title': 'About the Tournament',
        'about-description': 'Since the first edition, the tournament aimed to attract a large number of participants, trying to create a tradition. The main objective is turning “Cupa AndoTech” into a major tournament with attractive prizes, bringing together tennis lovers in Brasov, both national and international players.',
        'location-title': '"Tenis Arena" sports club',
        'location-subtitle': 'Tournament location',
        'location-description': 'The sports club "Tenis Arena" is a sport complex located in Brasov, which has a total number of nine clay courts, 3 courts indoor, 3 courts covered with balloon in winter and 3 courts outdoors.',
        'hosting-title': 'Acommodation',
        'hosting-subtitle': 'Alinalex Hotel / Tenis Arena',
        'hosting-description': 'Away from the city bustle, but only 10 minutes from the historic and commercial center of Brasov, both accommodation options are characterized by comfort and exceptional services. Being part of the  hotel Alinalex and close to the Tenis Arena Complex, the aqua land “Paradisul acvatic” is open 365 days / year for those who are seeking for relaxation, sports and entertainment.',
        'brasov-title': 'Brașov',
        'brasov-subtitle': 'Tournament host city',
        'brasov-description': 'The city of Braşov is a Romanian tourist landmark. Known for historical monuments with unique architecture, for the various festivals and cultural events who are held every year and for his location between mountains, Braşov is a true cultural exponent, a prolific city that always attracts many tourists.',
        'about-btn': 'Tournament information',
        'location-btn': 'Tournament location',
        'brasov-btn': 'About Brașov',
        'hosting-btn': 'Acommodation',
        'offer-btn': 'Andotech cup offer',
        'registration-name': 'Name',
        'registration-email': 'Email',
        'registration-phone': 'Phone',
        'registration-level': 'Level',
        'registration-comments': 'Comments (optional)',
        'registration-nationality': 'Nationality',
        'registration-submit-btn': 'Register',
        'registration-reset-btn': 'Reset',
        'registration-level-placeholder': '- Level -',
        'registration-level-4': 'Beginner',
        'registration-level-5': 'Medium',
        'registration-level-6': 'Advanced',
        'registration-level-7': 'Superior',
        'registration-level-8': 'Expert',
        'offer-btn': 'Andotech cup offer',
        'offer-title': 'AndoTech Cup 1 - 5 June 2017',
        'offer-acommodation-title': 'Accommodation',
        'offer-acommodation-desc': '4 nights accommodation with breakfast',
        'offer-transfer-title': 'Airport Transfer',
        'offer-transfer-desc': 'Bucharest - Brasov - Bucharest',
        'offer-tax-title': 'Participation fee',
        'offer-tax-desc': 'Registration for the tournament and an accommodation match',
        'offer-park-title': 'Aquapark Access',
        'offer-park-desc': 'Access 3h / day at “Paradisul acvatic”',
        'offer-dinner-title': 'Festive Dinner',
        'offer-dinner-desc': 'Dinner at the award ceremony',
        'offer-prize-title': 'Award ceremony',
        'offer-prize-desc': 'Prizes offered by sponsors',
        'offer-trip-title': 'Trip to Bran',
        'offer-trip-desc': 'Visit Bran Castle',
        'offer-assistence-title': 'Tourist Assistance',
        'offer-assistence-desc': 'Local informations',
        'package-standard': 'Standard package',
        'package-extra': 'Extra package',
        'fill-form-message': 'Please fill in all the fields',
        'registration-limit': 'You can register until the 10th of May 2017',
        'acommodation-placeholder': '- Choose acommodation -',
        'acommodation-paradis': 'Hotel Alinalex',
        'acommodation-arena': 'Tenis Arena complex',
        'registration-confirmation-title':'Thank you for your registration',
        'registration-confirmation-message':'The registration will be validated once you will receive the confirmation email</br> Please check that the massage doesn\'t end up in "Spam"',
        'registration-confirmation-content': `
        	<p> If you don't receive the confirmation email within 24 hours, you can contact us by <a href="tel:+40740755516" class="icon fa-phone"
                style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"> phone</a>, </br> <a href="mailto:cupa.andotech@gmail.com"
                class="icon fa-envelope" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"> e-mail</a> or <a href="https://www.facebook.com/cupaAndotech"
                target="_blank" class="icon fa-facebook" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"> facebook</a>. 
            </p>
            <p> Do you want to register someone else?</br>
                <a href="#register" class="icon fa-user-plus" id="register-back-btn" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"
                ng-click="resetRegistration()"> back to registration </a>
            </p>
        `
   
 },
    ro: {
        'main-title': 'Turneul de tenis - Cupa AndoTech',
        'main-desc': 'Ediția internațională',
        'main-date': '1 - 5 Iunie 2017',
        'page-title': 'Cupa AndoTech',
        'home-btn': 'Acasă',
        'ddl2-btn': 'Alte pagini',
        'ddl3-btn': 'Ediții',
        'register-btn': 'Înscriere',
        'more-info': 'mai multe detalii',
        'description': '"Cupa Andotech" este un turneu de tenis dedicat jucătorilor amatori, organizat de S.C. Andotech S.R.L, în colaborare cu baza sportivă Tenis Arena din Brașov.',
        'about-title': 'Informații despre turneu',
        'about-description': 'Încă de la prima ediție, turneul Cupa AndoTech și-a propus atragerea unui număr cât mai mare de participanți, încercând să creeze o tradiție. Astfel, obiectivul principal este transformarea într-un turneu major dotat cu premii atractive, care să reunească la Brașov pasionați ai tenisului, atât la nivel național cât și la nivel internțional.',
        'location-title': 'Baza sportivă Tenis Arena',
        'location-subtitle': 'Locație turneu',
        'location-description': 'Clubul Sportiv Tenis Arena este o bază sportivă, situată în Brașov, care dispune de un număr total de 9 terenuri pe zgură, 3 în sală, 3 acoperite cu balon pe perioada iernii și 3 în aer liber.',
        'hosting-title': 'Acomodare',
        'hosting-subtitle': 'Hotel Alinalex / Tenis Arena',
        'hosting-description': 'Ferite de agitaţia oraşului, dar la numai 10 minute de centrul istoric al Braşovului, cele două variante de cazare se remarcă prin confort şi servicii de excepţie. Aflat în cadrul hotelului ALINALEX şi în  apropiere de baza sportivă Tenis Arena, aqualandul Paradisul Acvatic este deschis 365 de zile/an pentru cei ce caută relaxare, sport şi distracţie.',
        'brasov-title': 'Brașov',
        'brasov-subtitle': 'Orașul gazdă al turneului',
        'brasov-description': 'Orașul Brașov, reședință de județ și municipiu al județului Brașov, este un reper turistic al României. Încărcat de monumente istorice, cu o arhitectură aparte, cu diverse festivaluri și evenimente culturale organizate an de an și situat între munți, Brașovul este un adevărat exponent cultural, un oraș prolific care atrage mereu foarte mulți turiști.',
        'about-btn': 'Informații Turneu',
        'location-btn': 'Locație Turneu',
        'brasov-btn': 'Despre Brașov',
        'hosting-btn': 'Acomodare',
        'registration-name': 'Nume',
        'registration-email': 'Email',
        'registration-phone': 'Telefon',
        'registration-level': 'Nivel',
        'registration-comments': 'Comentarii (opțional)',
        'registration-nationality': 'Naționalitate',
        'registration-submit-btn': 'Înscriere',
        'registration-reset-btn': 'Resetare',
        'registration-level-placeholder': '- Nivel -',
        'registration-level-4': 'Începător',
        'registration-level-5': 'Mediu',
        'registration-level-6': 'Avansat',
        'registration-level-7': 'Superior',
        'registration-level-8': 'Expert',
        'offer-btn': 'Ofertă cupa Andotech',
        'offer-title': 'Cupa AndoTech 1 - 5 Iunie 2017',
        'offer-acommodation-title': 'Cazare',
        'offer-acommodation-desc': '4 nopţi de cazare cu mic dejun',
        'offer-transfer-title': 'Transfer aeroport',
        'offer-transfer-desc': 'Bucureşti – Braşov - Bucureşti',
        'offer-tax-title': 'Taxă de participare',
        'offer-tax-desc': 'Înscriere la turneu şi un meci de acomodare',
        'offer-park-title': 'Acces aquapark',
        'offer-park-desc': 'Acces 3h/zi la paradisul Acvatic',
        'offer-dinner-title': 'Cină festivă',
        'offer-dinner-desc': 'Cină în cadrul festivităţii de premiere',
        'offer-prize-title': 'Festivitate de premiere',
        'offer-prize-desc': 'Premii acordate de către sponsori',
        'offer-trip-title': 'Excursie Bran',
        'offer-trip-desc': 'Vizită la Castelul Bran',
        'offer-assistence-title': 'Asistenţă turistică',
        'offer-assistence-desc': 'Servicii de informare la nivel local',
        'package-standard': 'Pachet standard',
        'package-extra': 'Pachet extra',
        'fill-form-message': 'Vă rugam să completați toate câmpurile',
        'registration-limit': 'Termenul limită pentru înscriere este 10 Mai 2017',
        'acommodation-placeholder': '- Alege acomodarea -',
        'acommodation-paradis': 'Hotel Alinalex',
        'acommodation-arena': 'Complexul Tenis Arena',
        'registration-confirmation-title':'Mulțumim pentru înscriere',
        'registration-confirmation-message':'Înscrierea va fi validată odată cu primirea e-mailului de confirmare.</br> Vă rugăm să verificați ca mesajul să nu ajungă în "Spam".',
        'registration-confirmation-content': `
        	<p> Dacă în termen de 24 de ore nu primiți confirmarea înscrierii, ne puteți contacta <a href="tel:+40740755516" class="icon fa-phone"
                style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"> telefonic</a>, </br>pe <a href="mailto:cupa.andotech@gmail.com"
                class="icon fa-envelope" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"> e-mail</a> sau pe <a href="https://www.facebook.com/cupaAndotech"
                target="_blank" class="icon fa-facebook" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"> facebook</a>. 
            </p>
            <p> Doriți să înscrieți o altă persoană? </br>
                <a href="#register" class="icon fa-user-plus" id="register-back-btn" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"
                ng-click="resetRegistration()"> înapoi la Înscriere </a>
            </p>
        `
    }
}